import { render, staticRenderFns } from "./add.vue?vue&type=template&id=4a0a3419"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace\\bms-front\\anj\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a0a3419')) {
      api.createRecord('4a0a3419', component.options)
    } else {
      api.reload('4a0a3419', component.options)
    }
    module.hot.accept("./add.vue?vue&type=template&id=4a0a3419", function () {
      api.rerender('4a0a3419', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/authority/company/add.vue"
export default component.exports