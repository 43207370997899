var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "big-data-container",
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "content-input-box content-input-box__100" },
          [
            _c(
              "div",
              { staticClass: "breadcrumb-box" },
              [
                _c(
                  "el-breadcrumb",
                  { attrs: { "separator-class": "el-icon-arrow-right" } },
                  [
                    _c("el-breadcrumb-item", { attrs: { to: "/company" } }, [
                      _vm._v("公司管理"),
                    ]),
                    _c("el-breadcrumb-item", [_vm._v("添加公司")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("company-base", { attrs: { "is-add": true } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }